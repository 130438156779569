import { DataModel } from "@essenza/core";

export function LessonModel() {
    DataModel.call(this, "lesson");
  
    this.list = () => {
      this.ExecuteQuery(`list: [lesson]  {*,
        #join: course (id=idcourse) {title}, -O id -D
      }`);
    };
  
    this.detail = (id) => {
      this.ExecuteApi("detail: lesson {*}", { id: id }, "ldetail");
    }
  }