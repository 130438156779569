import { useRef } from "react";
import { Button, Col, Input, List, Progress, Row, Select, Table } from "antd";

import { useControl } from "@essenza/react";
import { CourseForm } from "./course_form";


import { ReactComponent as SearchIcon } from '../assets/svg/search-icon.svg';
import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';
import { MdDeleteForever } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";

const { Option } = Select;

function CourseListController(c) {
    c.skin = CourseListView;
    let store;
    c.command = {
        EDIT: (source) => {

            c.show(<CourseForm label="Aggiorna" npath="/" source={source} />, { title: 'Modifica Corso' });

            //c.setSource("course.item", item); //Forse dovrebbe farlo Model? si che dovrebbe avere anche varie source
            //openPopup(<CourseForm />, "Modifica Corso", null, {excludeOk: true});
        },
        VIEW: (item, { model }) => {
            //model.read(CourseModel, (m)=> m.item(item.id));
            c.navigate("/course", item.id);
        },
        /*DELETE: (data, { model}) => {
            data.node.delete(data.item).then((r)=> {
                c.setSource("course.list", null);
                model.read(CourseModel, m => m.list());
            })
        },*/
        DELETE: (node, { data }) => {
            c.openPopup("Sei sicuro di voler eliminare il corso selezionato?", "Elimina", 400, {
                onconfirm: () => {
                    node.delete(data.id);
                }
            });
        },
        SEARCH: (list, { data }) => {
            if (!list) return;

            if (!store)
                store = list;
            const txt = data.text.toLowerCase();
            const s = data.state;
            const cat = data.category;

            c.setSource("course.list", store.filter((item) => (txt === '' || item.title.toLowerCase().indexOf(txt) > -1) && (s === '' || s === item.state) && (cat === 0 || cat === item.idcategory)));
        }
    }
}

export function CourseListView({ source, categories }) {
    const [control] = useControl(CourseListController);
    const filter = useRef({ text: '', state: '', category: 0 }).current;
    return (
        <>
            <Row gutter={28} style={{ marginLeft: '0px' }} className="custom-secondary-header content-max-width bg-sec">
                <Col span={6} className="custom-column-vertical-center-alignment">
                    <h2 style={{ marginBottom: "0" }}>Tutti i corsi</h2>
                </Col>
                <Col span={8}>
                    <Input onChange={(e) => { filter.text = e.currentTarget.value; control.execute("SEARCH", source.data, filter) }} prefix={<SearchIcon />} placeholder="Cerca corso" className="search-filter">
                    </Input>
                </Col>
                <Col span={4}>
                    <Select onChange={(v) => { filter.state = v; control.execute("SEARCH", source.data, filter) }} placeholder="Stato" style={{ width: '100%' }}>
                        <Option value="">Tutti</Option>
                        <Option value="B">Bozza</Option>
                        <Option value="P">Pubblicato</Option>
                    </Select>
                </Col>
                <Col span={6}>
                    <Select onChange={(v) => { filter.category = v; control.execute("SEARCH", source.data, filter) }} placeholder="Categorie" style={{ width: '100%' }}>
                        <Option value={0}>Tutte le Aree</Option>
                        {categories.data && categories.data.map((item) => <Option value={item.id}>{item.tname}</Option>)}
                    </Select>
                </Col>
            </Row>
            <Row className='section-background'>
                <Col span={24}>
                    <Row className='mx-md'>
                        <Col span={24} >
                            <Table rowKey="id" columns={courseCols(control, source)} dataSource={source?.getData(null, true)} pagination={false} className="custom-table" showHeader={false}>

                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

function courseCols(control, source) {
    //const npatient = node.discendant("patient");
    return [
        {
            title: "Titolo",
            dataIndex: "title",
            key: "id",
            render: (text, record) => {
                return (<h3 className="pointer" onClick={() => control.execute("VIEW", record)}>{record.title}</h3>);
            },
        },
        {
            title: "Tipo",
            dataIndex: "state",
            key: "id",
            width: 200,
            render: (text, record) => {
                return (<h6 className="color-3">{record.state === 'B' ? 'Bozza' : 'Pubblicato'}</h6>);
            },
        },
        {
            title: "Elimina",
            dataIndex: "id",
            key: "id",
            width: 200,
            render: (text, record) => {
                return (<Button className="secondaryButton" onClick={() => control.execute("DELETE", source.node, record)}>Elimina <MdDeleteForever /></Button>)
            },
        },
        {
            title: "Modifica",
            dataIndex: "id",
            key: "id",
            width: 200,
            //"EDIT", record
            render: (text, record) => {
                return (<Button onClick={() => control.execute("VIEW", record)} className="primaryButton">Modifica<RiEdit2Fill /></Button>)
            },
        },
    ]
}

function CoursesListController(c) {
    c.skin = CourseList;
    c.command = {

    }
}

export function CourseList({ source, editable, span }) {
    span = span || 2;
    const h = 280 / span;
    const [control] = useControl(CoursesListController);
    const labels = ['Inizia', 'Continua', 'Vai al Corso'];
    const categories = useRef(control.getSource("category.list")).current;//useGraph2(CategoryModel, "list").data;
    //debugger;
    const progress = (count, total, item) => {
        if (count === 0) {
            item.index = 0;
            return ' ';//'0% Completato';
        }
        else if (count < total) {
            item.index = 1;
            return <div><Progress trailColor="#8B9CCE" strokeColor="#264395" percent={count / total * 100} steps={total || 2} /> Completato</div>//<h5>Completato</h5>
        }
        else {
            item.index = 2;
            return <div><Progress trailColor="#8B9CCE" strokeColor="#264395" percent={count / total * 100} steps={total || 2} /> Completato</div>;
        }
    }

    if (!source.data) return null;
    //{cover "/lesson/" + item.lessonid + ".jpg"} , height: h + 'px'
    return (
        <>
            <div className="" style={{ padding: '24px' }}>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: span,
                        xxl: span,
                    }}
                    dataSource={source.getCollection()}
                    renderItem={item => (
                        <List.Item>
                            <Row className="bg-sec" justify="center" align="middle" style={{ padding: '32px', borderRadius: '4px' }}>
                                <Col span={24} md={9} ><img src={"/lesson/" + item.lessonid + ".jpg"} style={{borderRadius: '4px'}} className="img-scale" alt="Copertina Corso" /></Col>
                                <Col span={24} md={15} className="col-max-width">

                                        <div className="pt-col ts-pri">{categories ? categories.find((c)=>c.id === item.idcategory)?.tname : ''}</div>
                                        <h6>{item.title}</h6>
                                        <div className="mt-1">{progress(item.count, item.total, item)}</div>
                                        <div style={{ marginTop: '12px' }}><Button onClick={() => control.navigate("/coursedetail", { state: item.id })} className="primaryButton">{labels[item.index]}<FrecciaBianca /></Button></div>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </div>
        </>
    )
}