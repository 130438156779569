import { Button, Col, Progress, Row } from "antd";
import { useControl } from "@essenza/react";
import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';
import { ReactComponent as Arrow } from '../assets/svg/icona_indietro.svg';

function CourseDetailController(c) {
    c.skin = CourseDetail;
    c.command = {
        NEXT: (lessons) => {
            if(!lessons || lessons.length === 0) return;
            for (let k = 0; k < lessons.length; k++) {
                const l = lessons[k];
                //debugger;
                if(l.state !== 'C'){
                    c.navigate("lessondetail", {state: l.id});
                    return;
                }    
            }
            c.navigate("lessondetail", {state: lessons[0].id});
        }
    }
}

export function CourseDetail({ source }) {
    const [control] = useControl(CourseDetailController);
    const course = source.data;
    if (!course) return null;
    return (
        <>
            <h2><Arrow className="pointer" onClick={()=>control.navigate(-1)} style={{marginRight: '24px'}}/>{course.title}</h2>
            <p className="elements-spacing-b32">{course.tdescription}</p>
            
            <Row gutter={28} className="single-section-custom ">
                {course.count > 0 && <Col span={24} md={16}>
                    <h5>Hai completato il {(course.count / course.total * 100).toFixed()}% delle lezioni
                        <Progress percent={course.count / course.total * 100} showInfo={false} strokeColor="#264395" trailColor="#8B9CCE" />
                    </h5>
                </Col>}
                <Col span={24} md={8} style={{marginTop: '0px'}}>
                    {course.link?<Button onClick={() => window.open(course.link, '_blank').focus()} className="primaryButton">
                        Vai al Corso
                        <FrecciaBianca />
                    </Button>
                    :
                    <Button onClick={() => control.execute("NEXT", source.getData('lessons'))} className="primaryButton">
                        Prossima lezione
                        <FrecciaBianca />
                    </Button>
                    }
                </Col>
            </Row>
        </>
    )
}